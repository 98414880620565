export default {
  api_server: "/api",
  localStoragePrefix: "gpdq_portal-qa__",
  postcoder_api_key: "PCW45-12345-12345-1234X",
  postcoder_identifier: "Testing",
  auth: {
    clientId: "6315b8d571595fa5c8a8f960",
    loginServer: "https://qa-portal.gpdq.health",
    sessionTimeout: 420
  },
};
